<div class="row">
  <div class="col-sm text-center">
    <table class="table table-sm my-table mx-auto" style="max-width: {{session.playerIds.length * 150 + 100}}px;">
      <thead>
      <tr>
        <th class="text-center" style="width: {{100 / (session.playerIds.length + 1)}}%">Round</th>
        <th *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left" style="width: {{100 / (session.playerIds.length + 1)}}%">
          <fa-icon [icon]="faCrown" *ngIf="playerId == session.winnerId" class="player-fa-icon" style="padding-top: 3px; display: inline-block;"></fa-icon>
          {{session.playerData[playerId].name}}
        </th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let _ of session.playerData[0].scores; let i = index">
          <td class="text-center">{{wildCard(i + 1)}}</td>
          <td *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left">
            {{session.playerData[playerId].scores[i] ? session.playerData[playerId].scores[i] : '-'}}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th class="text-center">Total</th>
          <th *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left">
            {{session.playerData[playerId].score}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">Outs</td>
          <td *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left">
            {{getOuts(session.playerData[playerId].scores)}}
          </td>
        </tr>
        <tr>
          <td class="text-center">Average</td>
          <td *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left">
            {{getAverage(session.playerData[playerId].scores)}}
          </td>
        </tr>
        <tr>
          <td class="text-center">Wilds</td>
          <td *ngFor="let playerId of session.playerIds" class="text-center text-nowrap border-left">
            {{session.playerData[playerId].wildCount}}
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary" (click)="quit()">Quit</button>
  </div>
</div>
