<form class="mx-auto" [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group">
    <label for="old-password">Current Password</label>
    <input type="password" formControlName="old_password" class="form-control" id="old-password"
           [class.is-invalid]="invalid(form.controls.old_password)">
    <app-input-error fieldName="Current Password" [fieldControl]="form.controls.old_password"></app-input-error>
  </div>
  <div class="form-group">
    <label for="password">New Password</label>
    <input type="password" formControlName="password" class="form-control" id="password"
           [class.is-invalid]="invalid(form.controls.password)">
    <app-input-error fieldName="New Password" [fieldControl]="form.controls.password"></app-input-error>
  </div>
  <div class="form-group">
    <label for="confirmation">Confirmation</label>
    <input type="password" formControlName="confirmation" class="form-control" id="confirmation"
           [class.is-invalid]="invalid(form.controls.confirmation)">
    <app-input-error fieldName="Confirmation" [fieldControl]="form.controls.confirmation"></app-input-error>
  </div>

  <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="submit()">
    {{submitted ? 'Saving' : 'Save'}}
  </button>
</form>
