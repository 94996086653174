export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDVuXnX4KVvHFNvg8T7PRe4ryCI8wCuxqU',
    authDomain: 'five-crowns-3b4a1.firebaseapp.com',
    databaseURL: 'https://five-crowns-3b4a1.firebaseio.com',
    projectId: 'five-crowns-3b4a1',
    storageBucket: 'five-crowns-3b4a1.appspot.com',
    messagingSenderId: '446132658465',
    appId: '1:446132658465:web:baf0b12ac4baf66a3b5e60'
  }
};
