<div class="row">
  <div class="col-12">
    <div class="max-width-limit mx-auto">
      <table class="table table-hover my-table">
        <thead>
        <tr>
          <th class="w40">Host</th>
          <th class="w30 text-center">Players</th>
          <!-- <th class="w30 text-center">Password</th> -->
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let session of sessions" [class.selected]="selected?.id == session.id" (click)="select(session)">
          <td>{{session.playerData[0].name}}<span style="display: none">{{session.id}}</span></td>
          <td class="text-center">{{session.playerIds.length}} / {{session.playerMax}}</td>
          <!-- <td class="text-center">{{session.password ? 'Yes' : 'No'}}</td> -->
          <td class="game-action"><fa-icon [icon]="faTrashAlt" (click)="deleteGame(session.id)"></fa-icon></td>
        </tr>
        <tr *ngIf="sessions.length === 0">
          <td colspan="3" class="text-center"><i>No games found</i></td>
        </tr>
        </tbody>
      </table>
      <div class="mx-auto" style="width: max-content;">
        <button class="btn btn-primary" (click)="hostGame()">Host Game</button>
        <button class="btn" [class.btn-primary]="selected" [class.btn-secondary]="!selected"
                [disabled]="!selected" (click)="joinGame(selected.id)">Join Game</button>
      </div>
      <div class="stats">
        <app-game-stats></app-game-stats>
      </div>
      <div class="recent-sessions" *ngIf="recentSessions.length > 0">
        <ng-container *ngFor="let recentSession of recentSessions">
          <div class="recent-session">
            <table>
              <tr>
                <td colspan="3" class="date">{{getGameLastUpdateAsDate(recentSession.finishDate)}}<span class="session-id">{{recentSession.id}}</span></td>
              </tr>
              <tr *ngFor="let playerId of recentSession.playerIds">
                <td class="player-icon"><fa-icon [icon]="recentSession.playerData[playerId].bot ? faRobot : faUser"></fa-icon></td>
                <td class="player-name">{{recentSession.playerData[playerId].name}}<fa-icon [icon]="faCrown" *ngIf="playerId == recentSession.winnerId" class="player-fa-icon" style="display: inline-block;"></fa-icon></td>
                <td class="score">{{recentSession.playerData[playerId].score}}</td>
              </tr>
            </table>
          </div>
      </ng-container>
      </div>
    </div>

  </div>
</div>
