import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { Stats } from '../models/stats.model';
import { prepareForUpdate } from "../firebase";
import { RealTimeUpdate } from '../real-time-update';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StatsService {
    stats: BehaviorSubject<Stats>;
    private rtu: RealTimeUpdate;

    constructor(private firestore: AngularFirestore) {
        this.rtu = new RealTimeUpdate(this.listenForUpdates.bind(this), this.handleUpdates.bind(this));
    }

    private collection(queryFn?: QueryFn): AngularFirestoreCollection<any> {
        return this.firestore
            .collection('stats', queryFn);
    }

    private listenForUpdates(id: string): Observable<any> {
        return this
            .collection()
            .doc(id)
            .valueChanges();
    }

    listenForStats(): Observable<any> {
        return this.collection((ref) => ref.limit(1)).valueChanges({ idField: 'id' });
    }

    getStats(): Promise<Stats> {
        return this
            .collection(ref => ref
                .limit(1))
            .get()
            .pipe(map(records => {
                if (records.size > 0) {
                    const record = records.docs[0];
                    return { ...record.data(), id: record.id } as Stats;
                }
                return null;
            }))
            .toPromise();
    }

    private handleUpdates(id: string, record: any): void {
        if (record) {
            record = { ...record, id };
        }
        this.stats.next(record);
    }

    update(id: string, data: Partial<Stats>): Promise<void> {
        return this
            .collection()
            .doc(id)
            .update(prepareForUpdate(data));
    }
}