import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Game } from 'src/app/shared/game/game';
import { SessionKey } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-game-final-hand',
  templateUrl: './final-hand.component.html',
  styleUrls: ['./final-hand.component.scss']
})
export class FinalHandComponent implements OnChanges {

  @Input() game: Game;
  @Input() sessionKey: SessionKey;

  public finalGame: Game;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.finalGame = this.game;
    if(this.game.winnerId !== undefined) {
      
    }
  }

  ngOnInit(): void {
    
  }

  isShouldShowHand(playerId: number): boolean {
    if(playerId === this.sessionKey.playerId) {
      return false; //never show the player's own hand here.
    }

    const playerCount = this.game.playerIds.length;
    let playersWithScore = 0;
    for (let playerIndex = 0; playerIndex < playerCount; playerIndex ++) {
      if(this.game.playerData[playerIndex].scores.length === this.game.round) {
        playersWithScore++;
      }
    }

    return this.game.playerData[playerId].scores.length === this.game.round
     || playersWithScore >= (playerCount - 1)
  }
}
