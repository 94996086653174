<div class="row">
  <div class="col-12">
    <form class="mx-auto" [formGroup]="form" (ngSubmit)="submit()">
      <div class="text-center">
        <img src="assets/favicon.svg" width="64" height="64" class="d-inline-block align-top" alt="">
      </div>
      <h2 class="text-center">
        Welcome to Five Crowns
      </h2>
      <div class="form-group">
        <label for="sign-in-email">Email</label>
        <input type="text" formControlName="email" class="form-control" id="sign-in-email"
               [class.is-invalid]="invalid(form.controls.email) || authError">
        <app-input-error fieldName="Email" [fieldControl]="form.controls.email"></app-input-error>
      </div>
      <div class="form-group">
        <label for="sign-in-password">Password</label>
        <input type="password" formControlName="password" class="form-control" id="sign-in-password"
               [class.is-invalid]="invalid(form.controls.password) || authError">
        <app-input-error fieldName="Password" [fieldControl]="form.controls.password"></app-input-error>
        <div *ngIf="authError && !invalid(form)" class="invalid-feedback d-block">Invalid Email or Password</div>
      </div>
      <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="submit()">
        {{submitted ? 'Signing in' : 'Sign In'}}
      </button> or <a href="#" (click)="switch()">Sign Up</a>
    </form>
  </div>
</div>
