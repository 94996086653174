<form class="mx-auto" [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group">
    <label for="name">Name</label>
    <input type="text" formControlName="name" class="form-control" id="name"
           [class.is-invalid]="invalid(form.controls.name)">
    <app-input-error fieldName="Name" [fieldControl]="form.controls.name"></app-input-error>
  </div>
  <div class="form-group">
    <label for="email">Email</label>
    <input type="text" formControlName="email" class="form-control" id="email"
           [class.is-invalid]="invalid(form.controls.email)">
    <app-input-error fieldName="Email" [fieldControl]="form.controls.email"></app-input-error>
  </div>
  <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="submit()">
    {{submitted ? 'Saving' : 'Save'}}
  </button>
</form>
