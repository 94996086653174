<div class="row">
  <div class="col-12">
    <form class="mx-auto" [formGroup]="form" (ngSubmit)="submit()">
      <div class="text-center">
        <img src="assets/favicon.svg" width="64" height="64" class="d-inline-block align-top" alt="">
      </div>
      <h2 class="text-center">
        Create you account
      </h2>
      <div class="form-group">
        <label for="sign-up-name">Name</label>
        <input type="text" formControlName="name" class="form-control" id="sign-up-name" maxlength="50"
               [class.is-invalid]="invalid(form.controls.name)">
        <app-input-error fieldName="Name" [fieldControl]="form.controls.name"></app-input-error>
      </div>
      <div class="form-group">
        <label for="sign-up-email">Email</label>
        <input type="text" formControlName="email" class="form-control" id="sign-up-email" maxlength="255"
               [class.is-invalid]="invalid(form.controls.email)">
        <app-input-error fieldName="Email" [fieldControl]="form.controls.email"></app-input-error>
      </div>
      <div class="form-group">
        <label for="sign-up-password">Password</label>
        <input type="password" formControlName="password" class="form-control" id="sign-up-password"
               [class.is-invalid]="invalid(form.controls.password)">
        <app-input-error fieldName="Password" [fieldControl]="form.controls.password"></app-input-error>
      </div>
      <div class="form-group">
        <label for="sign-up-confirmation">Confirmation</label>
        <input type="password" formControlName="confirmation" class="form-control" id="sign-up-confirmation"
               [class.is-invalid]="invalid(form.controls.confirmation)">
        <app-input-error fieldName="Confirmation" [fieldControl]="form.controls.confirmation"></app-input-error>
      </div>
      <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="submit()">
        {{submitted ? 'Signing Up' : 'Sign Up'}}
      </button> or <a href="#" (click)="switch()">Sign In</a>
    </form>
  </div>
</div>
