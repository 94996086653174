import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/auto-unsubscribe';
import { PlayerStats, Stats } from 'src/app/shared/models/stats.model';
import { StatsService } from 'src/app/shared/services/stats.service';

@AutoUnsubscribe
@Component({
  selector: 'app-game-stats',
  templateUrl: './game-stats.component.html',
  styleUrls: ['./game-stats.component.scss']
})
export class GameStatsComponent implements OnInit {

  $stats: Subscription;
  stats: Stats;

  constructor(private statsService: StatsService) { }

  ngOnInit(): void {
    this.stats = new Stats();
    this.stats.players = [];
    this.$stats = this.statsService.listenForStats().subscribe(this.onStatsChange.bind(this));
  }

  onStatsChange(stats: Stats[]): void {
    this.stats = Stats.deserialize(stats.find(x => { return true;}));
  }

  sortPlayers(players: PlayerStats[]) {
    return players.sort((a, b) => {
        const percentA = a.percentWon;
        const percentB = b.percentWon;
        if(percentA === percentB) {
          return 0;
        }
        if(percentA > percentB) {
          return -1;
        }

        return 1;
    });
  }

  getPercentage(percent: number) {
    return (Math.round(percent * 100  * 100) / 100).toFixed(2);
  }

}
