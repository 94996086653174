<div class="final-hand" *ngIf="game.winnerId !== undefined">
    <h3>Final hands</h3>
    <div class="playercontainer" *ngFor="let playerId of game.playerIds">
        <ng-container *ngIf="isShouldShowHand(playerId)">
            <div class="playername">{{game.playerData[playerId].name}} <span *ngIf="game.playerData[playerId].scores[game.round - 1] > -1">({{game.playerData[playerId].scores[game.round - 1]}})</span></div>
            <div class="d-flex">
                <ng-container *ngFor="let hand of game.playerData[playerId].hands">
                    <ng-container *ngFor="let card of hand.cards">
                        <app-game-card class="game-card flex-left final-hand-card" [card]="card"></app-game-card>
                    </ng-container>
                    <ng-container *ngIf="hand.cards.length > 0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</ng-container>
                    
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>