<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" (click)="open(pages.Home)" href="#">
    <img src="assets/favicon.svg" width="30" height="30" class="d-inline-block align-top" alt="">
    Five Crowns
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" [class.active]="page === pages.Home" *ngIf="user">
        <a class="nav-link" (click)="open(pages.Home)" href="#">Game</a>
      </li>
      <li class="nav-item" *ngIf="user && sessionKey">
        <a class="nav-link" (click)="quitGame()" href="#">Quit</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" [class.active]="page === pages.SignIn" *ngIf="!user">
        <a class="nav-link" (click)="open(pages.SignIn)" rel="nofollow" href="#">Sign in</a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link disabled" href="#">{{user.name}}</a>
      </li>
      <li class="nav-item" [class.active]="page === pages.Profile" *ngIf="user">
        <a class="nav-link" (click)="open(pages.Profile)" href="#">Profile</a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link" rel="nofollow" href="#" (click)="signOut()">Sign out</a>
      </li>
    </ul>
  </div>
</nav>
