<div class="game-stats">
    <table *ngIf="stats.players">
        <thead>
            <th></th>
            <th class="stat" title="Won">W</th>
            <th class="stat" title="Lost">L</th>
            <th class="stat" title="Total games">&Sigma;</th>
            <th class="stat" title="Average score">x̄</th>
            <th class="stat" title="Percent wins">%</th>
        </thead>
        <tr *ngFor="let player of sortPlayers(stats.players)">
            <td>{{player.name}}</td>
            <td class="stat">{{player.wins}}</td>
            <td class="stat">{{player.losses}}</td>
            <td class="stat">{{player.totalGames}}</td>
            <td class="stat">{{player.averageScore}}</td>
            <td class="stat percent">{{getPercentage(player.percentWon)}}</td>
        </tr>
    </table>
</div>