export class Stats {
    id?: string;
    playerData: object; // player data for persisting via serialization.
    players: PlayerStats[]; // player data used within the app
    playerIds: number[] = [];

    serialize(): object {
        const statObject = { players: [], playerIds: [] };

        for (let playerIndex = 0; playerIndex < this.players.length; playerIndex++) {
            const player = this.players[playerIndex];
            const value = {} as Partial<PlayerStats>;

            value.id = player.id;
            value.name = player.name;
            value.wins = player.wins;
            value.losses = player.losses;
            value.averageScore = player.averageScore;
            value.totalGames = player.totalGames;
            value.percentWon = player.percentWon;
            value.compoundScore = player.compoundScore;

            statObject[`playerData.${playerIndex}`] = value;
            statObject.playerIds.push(playerIndex);
        }

        return statObject;
    }

    static deserialize(statsObject: Stats): Stats {

        var returnStats = new Stats();
        returnStats.id = statsObject.id;
        returnStats.players = [];
        Object.keys(statsObject.playerData).map(key => {
            const value = statsObject.playerData[key];
            const player = new PlayerStats();

            returnStats.playerIds.push(parseInt(key, 10))

            player.id = value.id;
            player.name = value.name;
            player.averageScore = value.averageScore;
            player.losses = value.losses;
            player.percentWon = value.percentWon;
            player.totalGames = value.totalGames;
            player.wins = value.wins;
            player.compoundScore = value.compoundScore;
            returnStats.players.push(player);
        });

        return returnStats;
    }
}

export class PlayerStats {
    id: string = "";
    name: string = '';
    totalGames: number = 0;
    averageScore: number = 0;
    wins: number = 0;
    losses: number = 0;
    percentWon: number = 0;
    compoundScore: number = 0;
}
