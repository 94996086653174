<footer class="footer navbar-light bg-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <span class="nav-item">Five Crowns © 2020 Andrew K.</span>
      </div>
      <div id="data-refresh-div" class="col text-right align-items-center" data-refresh="true" style="display: none;"></div>
    </div>
  </div>
</footer>
