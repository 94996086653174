<div *ngIf="game">
  <div class="float-md-right">
    <table class="table my-table">
      <thead>
      <tr>
        <th colspan="2" class="audio">
          <fa-icon class="toggle-sound" [icon]="isSoundEnabled() ? faVolumeUp : faVolumeMute" (click)="toggleSoundEnabled()"></fa-icon>
        </th>
        <th>Player</th>
        <th colspan="2" class="text-right">Score</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let playerId of game.playerIds; let i = index">
        <td class="text-center player-status player-fa-icon">
          <app-game-hourglass *ngIf="playerId === game.currentId"></app-game-hourglass>
          <fa-icon [icon]="faCrown" *ngIf="playerId === game.winnerId" style="padding-top: 3px; display: block;"></fa-icon>
        </td>
        <td style="width: 1px; padding-top: 12px;" class="text-center player-status player-fa-icon">
          <fa-icon [icon]="session.playerData[playerId].bot ? faRobot : faUser"></fa-icon>
        </td>
        <td>{{game.playerData[playerId].name}}</td>
        <td class="text-right">{{game.playerData[playerId].score}}</td>
        <td class="text-right" style="padding-left: 0;">(+{{game.round > 1 ? game.playerData[playerId].scores[game.playerData[playerId].scores.length-1] : 0}})</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div data-list="deckCard" class="player-hand rounded"
         [class.sort-disabled]="!game.playerCanDraw"
         [sortablejs]="[null]" [sortablejsOptions]="deckSortOptions">
      <app-game-card [card]="null" (click)="drawDeck()"></app-game-card>
    </div>
    <div data-list="openCard" class="player-hand rounded hide-if-two-div" style="margin-left: 10px;"
         [class.sort-disabled]="!game.playerCanDraw"
         [sortablejs]="openCards" [sortablejsOptions]="openSortOptions">
      <app-game-card class="hide-if-two-card" [card]="openCard" (click)="drawOpen()"></app-game-card>
    </div>
  </div>

  <!-- <div class="float-right"> -->
    <!-- <button (click)="bot.autoGroup()" [disabled]="!this.game.canDraw && !this.game.canDiscard">Auto Group</button> -->
    <!-- <button (click)="bot.autoDraw(this)" [disabled]="!this.game.canDraw">Auto Draw</button> -->
    <!-- <button (click)="bot.autoDiscard(this)" [disabled]="!this.game.canDiscard">Auto Discard</button> -->
  <!-- </div> -->
  <p style="padding-top: 10px;">
    <span style="margin-left: 12px; margin-right: 24px;"><strong class="player-fa-icon wild-card">{{game.wildCard}}</strong>s are wild</span>
    <span *ngIf="game.playerCanDraw">Your Turn <fa-icon class="player-fa-icon" [icon]="faArrowCircleDown"></fa-icon> Draw a card</span>
    <span *ngIf="game.playerCanDiscard">Your Turn <fa-icon class="player-fa-icon" [icon]="faArrowCircleUp"></fa-icon> Discard a card</span>
    <span *ngIf="!game.isCurrent"><app-game-hourglass class="player-fa-icon"></app-game-hourglass> Waiting for {{game.playerData[game.currentId]?.name}}</span>
    <span *ngIf="game.winnerId !== undefined">&nbsp;&nbsp;<fa-icon [icon]="faExclamationTriangle" class="player-fa-icon blinky icon-red"></fa-icon>&nbsp;&nbsp;Last Turn</span>
  </p>

  <div class="row">
    <div class="col-sm flex-row" *ngFor="let hand of game.player.hands; let handIdx = index">
      <div class="player-hand-score" *ngIf="hand.score(game.round) > 0">
        <div>{{hand.score(game.round)}}</div>
      </div>

      <div [attr.data-list]="handIdx" class="d-flex player-hand rounded" [class.run-or-set]="game.isRunOrSet(hand)" [class.has-score]="hand.score(game.round) > 0"
           [sortablejs]="hand.cards" [sortablejsOptions]="handSortOptions">
        <app-game-card *ngFor="let card of hand.cards; index as cardIdx"
                       class="flex-left" [card]="card" (click)="discard(handIdx, cardIdx)">
        </app-game-card>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm flex-row">
      <app-game-final-hand [game]="game" [sessionKey]="sessionKey"></app-game-final-hand>
    </div>
  </div>
</div>
