import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { Session, SessionStates } from "src/app/shared/models/session.model";
import { AutoUnsubscribe } from "src/app/shared/auto-unsubscribe";
import { SessionService } from "src/app/shared/services/session.service";
import { UserService } from "src/app/shared/services/user.service";
import { faCrown, faRobot, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

@AutoUnsubscribe
@Component({
  selector: 'app-home-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {
  readonly faUser = faUser;
  readonly faRobot = faRobot;
  readonly faCrown = faCrown;
  readonly faTrashAlt = faTrashAlt;

  $sessions: Subscription
  sessions: Session[] = [];
  $recentSessions: Subscription;
  recentSessions: Session[] = [];
  selected: Session;
  submitted: boolean = false;

  constructor(private userService: UserService,
    private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.$sessions = this.sessionService.listenForSessions()
      .subscribe(this.onSessionsChange.bind(this));
    this.$recentSessions = this.sessionService.listenForRecentSessions()
      .subscribe(this.onRecentSessionsChange.bind(this));
  }

  onSessionsChange(sessions: Session[]): void {
    this.sessions = sessions;
    if (this.selected && !sessions.some(session => session.id === this.selected.id)) {
      this.selected = null;
    }
  }

  onRecentSessionsChange(recentSessions: Session[]): void {
    this.recentSessions = recentSessions.sort((a, b) => {
      if (a.lastUpdate === undefined && b.lastUpdate === undefined) {
        return 0;
      }

      if (a.lastUpdate === undefined) {
        return -1;
      }

      if (b.lastUpdate === undefined) {
        return 1;
      }

      if (a.lastUpdate < b.lastUpdate) {
        return 1;
      }

      if (a.lastUpdate > b.lastUpdate) {
        return -1;
      }

      return 0;
    });
  }

  select(session): void {
    if (this.selected?.id === session.id) {
      this.selected = null;
    } else {
      this.selected = session;
    }
  }

  hostGame(): void {
    if (this.submitted) return;
    this.submitted = true;

    const user = this.userService.user.value;
    const player = {
      id: user.id,
      name: user.name
    };
    const session = {
      state: SessionStates.Waiting,
      playerIds: [0],
      playerData: { 0: player },
      playerNextId: 1,
      playerMax: 7
    };

    this.sessionService.create(session)
      .then(session => {
        this.sessionService.sessionKey.next({ sessionId: session.id, playerId: 0 });
        this.submitted = false;
      })
      .catch(error => console.error(error));
  }

  joinGame(sessionId: string) {
    if (this.submitted) return;
    this.submitted = true;

    const user = this.userService.user.value;
    const player = {
      id: user.id,
      name: user.name
    };
    this.sessionService.join(sessionId, player)
      .then(playerId => {
        const sessionKey = playerId ? { sessionId, playerId } : null;
        this.sessionService.sessionKey.next(sessionKey);
        if (!sessionKey) {
          alert('Failed to join the game');
        }
        this.submitted = false;
      })
      .catch(error => {
        this.submitted = false;
        console.error(error);
      });
  }

  deleteGame(sessionId: string): void {
    if (confirm('Are you sure you want to delete this game?')) {
      this.sessionService.delete(sessionId)
      .catch(error => { console.error(error); });
    }
  }

  getGameLastUpdateAsDate(timestamp: number): string {
    var d = new Date(timestamp); // The 0 there is the key, which sets the date to the epoch
    return this.padIfShort(d.getDate()) + "-" + this.padIfShort((d.getMonth() + 1)) + "-" + d.getFullYear() + " " +
      this.padIfShort(d.getHours()) + ":" + this.padIfShort(d.getMinutes());
  }

  private padIfShort(input: number): string {
    if (input.toString().length > 1) {
      return input.toString();
    }

    return '0' + input.toString();
  }
}
